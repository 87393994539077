@import '/src/styles/mixin';
@import '/src/styles/variables';

.completeProfile {
  padding: 56px 32px;
  border-radius: 32px;
  background-color: $color-neutral-theme-50;
}

.companyWebsites {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.companyWebsites_buttonMore {
  width: 103px;
  height: 32px;
}

.companyWebsites_buttonRemoveWebsite {
  margin-top: 11.5px !important;
  position: absolute !important;
  right: -34px;

  @include tabletAndMobile() {
    right: 0;
  }
}

.companyWebsites_inputWrapper {
  display: flex;
  position: relative;
  flex-direction: row;
  gap: 8px;
  align-items: flex-start;
  width: 100%;

  @include tabletAndMobile() {
    input {
      padding-right: 32px !important;
    }
  }

  svg {
    color: $color-neutral-theme-300;
  }
}

.title {
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  color: $color-neutral-theme-300;
}