@import '/src/styles/mixin';
@import '/src/styles/variables';

.vccContainer {
  max-width: 269px;
  width: 100%;
  border-radius: 16px;

  display: flex;
  flex-direction: column;
  gap: 16px;
}

.vvcInfo {
  background-color: $neutral-white;
  border-radius: 16px;
  box-shadow: 0px 4px 20px 0px #0000000D;
  padding: 16px;

  display: flex;
  flex-direction: column;
  gap: 16px;
}