@import '/src/styles/mixin';
@import '/src/styles/variables';

.count {
  position: absolute;
  top: 5px;
  right: 5px;
  height: 12px;
  min-width: 12px;
  padding: 2px;
  border-radius: 6px;
  color: #FFF;
  background-color: #FB5230;
  @include typo(8px, 700, 1);
}
