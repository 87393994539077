@import '/src/styles/mixin';
@import '/src/styles/variables';

.datepicker {
  display: inline-block;
  position: relative;
  width: fit-content;
  border: 1px solid $neutral-N500;
  border-radius: 12px;

  &:hover {
    @include box-shadow(inset 0px 0px 0px 1px $neutral-50);
  }
  &.focus {
    border: 1px solid $green-300;
    @include box-shadow(inset 0px 0px 0px 2px $green-75);
  }
  &.error {
    border: 1px solid $negative-500;
    @include box-shadow(inset 0px 0px 0px 1px $negative-500);
  }
  &.disabled {
    cursor: not-allowed;
    color: $neutral-40;
    background-color: rgba(0, 0, 0, 0.04);
    border: none;
    @include box-shadow(inset 0px 0px 0px 1px $neutral-40);
  }
}

.datepickerInput {
  width: 100%;
  height: 48px;
  padding: 8px 12px;
  border-radius: 12px;
  outline: none;
  border: none;
  box-shadow: none;
  font-size: 1.4rem;
  line-height: 2rem;
  color: $neutral-900;

  &:focus {
    border-color: $green-300;
  }

  &::placeholder {
    color: #aaa;
  }
}

