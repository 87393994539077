@import '/src/styles/variables';

.container {
  display: flex;
  flex-direction: column;

  width: min(calc(100vw - 300px), max(70vw, 800px));
  height: 100%;
  overflow: auto;

  position: absolute;
  top: 0;
  right: 0;

  font-size: 1.6rem;
  background-color: $neutral-white;
}

.content {
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: $color-white;
  flex: 1;
  overflow: auto;
  padding-top: 16px;

  &_wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  &_action {
    position: sticky;
    bottom: 0;
    z-index: 888;
  }
}

.video {
  width: 280px;
  height: 400px;
}

.borderBottom {
  border-bottom: 1px solid #DCDCDC;
}

.fullname {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 6px;
  color: $neutral-700
}

.userRole {
  font-size: 10px;
  color: $neutral-500;
  margin-bottom: 6px;
}

.jobTitle {
  font-size: 28px;
  font-weight: 500;
  color: $neutral-700
}


.actionButtons {
  button {
   &:first-child {
    height: 32px;
    width: 74px;
   }
   &:last-child {
      padding: 0;
      width: 32px;
      height: 32px;
      min-width: 32px;
      min-height: 32px;
      border-radius: 999px;
      border: none;
   }
  }
}