@import '/src/styles/variables';
@import '/src/styles/mixin';

.contactUserContainer {
  position: absolute;
  padding: 28px;
  width: calc(100% + 27px);
  height: 100%;
  transform: translateX(-100%);
  background-color: $neutral-white;
  display: flex;
  flex-direction: column;
  gap: 32px;
  border-right: 1px solid $grey-02;
}

.videoContainer {
  max-width: 272px;
  width: 100%;
}