@import '/src/styles/variables';

.customCheckboxWrapper {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.customCheckbox {
  display: none;
}

.checkboxMark {
  min-width: 16px;
  min-height: 16px;
  border: 1px solid $green-600;
  border-radius: 4px;
  position: relative;
  display: inline-block;
  text-align: center;
  transition: background-color 0.2s ease, border-color 0.2s ease;
}

.customCheckbox:checked + .checkboxMark {
  background-color: $green-600;
  border-color: $green-600;
}

.customCheckboxWrapper:hover .checkboxMark {
  border-color: $green-600;
}