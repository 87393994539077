$spacers: (
  auto: auto,
  0: 0,
  1: #{(1 * 4)}px,
  2: #{(2 * 4)}px,
  3: #{(3 * 4)}px,
  4: #{(4 * 4)}px,
  5: #{(5 * 4)}px,
  6: #{(6 * 4)}px,
  7: #{(7 * 4)}px,
  8: #{(8 * 4)}px,
  9: #{(9 * 4)}px,
  10: #{(10 * 4)}px,
  11: #{(11 * 4)}px,
  12: #{(12 * 4)}px,
  13: #{(13 * 4)}px,
  14: #{(14 * 4)}px,
  15: #{(15 * 4)}px,
  16: #{(16 * 4)}px,
  17: #{(17 * 4)}px,
  18: #{(18 * 4)}px,
  19: #{(19 * 4)}px,
  20: #{(20 * 4)}px,
);

@each $size, $length in $spacers {
  .square-#{$size} {
    width: $length;
    height: $length;
  }

  .square-#{$size}-p {
    width: $length !important;
    height: $length !important;
  }

  .round-#{$size} {
    border-radius: $length;
  }

  .round-#{$size}-p {
    border-radius: $length !important;
  }

  .gap-#{$size} {
    gap: $length;
  }

  .row-gap-#{$size} {
    row-gap: $length;
  }

  .column-gap-#{$size} {
    column-gap: $length;
  }

  .gap-#{$size}-p {
    gap: $length;
  }

  .p-#{$size} {
    padding: $length;
  }

  .p-#{$size}-p {
    padding: $length !important;
  }

  .px-#{$size} {
    padding-left: $length;
    padding-right: $length;
  }

  .px-#{$size}-p {
    padding-left: $length !important;
    padding-right: $length !important;
  }

  .py-#{$size} {
    padding-top: $length;
    padding-bottom: $length;
  }

  .py-#{$size}-p {
    padding-top: $length !important;
    padding-bottom: $length !important;
  }

  .pt-#{$size} {
    padding-top: $length;
  }

  .pt-#{$size}-p {
    padding-top: $length !important;
  }

  .pr-#{$size} {
    padding-right: $length;
  }

  .pr-#{$size}-p {
    padding-right: $length !important;
  }

  .pb-#{$size} {
    padding-bottom: $length;
  }

  .pb-#{$size}-p {
    padding-bottom: $length !important;
  }

  .pl-#{$size} {
    padding-left: $length;
  }

  .pl-#{$size}-p {
    padding-left: $length !important;
  }

  .m-#{$size} {
    margin: $length;
  }

  .m-#{$size}-i {
    margin: $length !important;
  }

  .mx-#{$size} {
    margin-left: $length;
    margin-right: $length;
  }

  .my-#{$size} {
    margin-top: $length;
    margin-bottom: $length;
  }

  .mt-#{$size} {
    margin-top: $length;
  }

  .mt-#{$size}-i {
    margin-top: $length !important;
  }

  .mt-#{$size}-p {
    margin-top: $length!important;
  }

  .mr-#{$size} {
    margin-right: $length;
  }

  .mb-#{$size} {
    margin-bottom: $length;
  }

  .ml-#{$size} {
    margin-left: $length;
  }

  .t-#{$size} {
    top: $length;
  }

  .b-#{$size} {
    bottom: $length;
  }

  .l-#{$size} {
    left: $length;
  }

  .r-#{$size} {
    right: $length;
  }

  .z-#{$size} {
    z-index: $size;
  }

  .width-#{$size} {
    width: $length;
  }

  .height-#{$size} {
    height: $length;
  }
}

/**
 * Width & Height
 */
@each $percent, $size in $percent-values {
  .w-#{$percent},
  .w-#{$percent}-p {
    width: $size;
  }

  .w-#{$percent}-i,
  .w-#{$percent}-p-i {
    width: $size !important;
  }

  .max-w-#{$percent},
  .max-w-#{$percent}-p {
    max-width: $size;
  }

  .h-#{$percent},
  .h-#{$percent}-p {
    height: $size;
  }
}

.w {
  &-full {
    width: 100%;
  }

  &-fit {
    width: fit-content;
  }

  &-min-fit {
    min-width: fit-content;
  }

  &-screen {
    width: 100vw;
  }
}

.h {
  &-full {
    height: 100%;
  }

  &-screen {
    height: 100vh;
  }

  &-fit {
    height: fit-content;
  }
}
