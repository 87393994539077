@import '/src/styles/mixin';
@import '/src/styles/variables';

.control {
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  transition: all .3s ease-in-out;
  display: flex;
  align-items: flex-end;
  border-radius: 16px;

  &.Mini {
    .controller {
      padding: 0 8px;
      height: 16px;
    }
  }

  .controller {
    width: 100%;
    height: 24px;
    padding: 0 16px 8px;

    display: flex;
    align-items: center;

    >div {
      width: 40px;
    }
  }
}

